var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _RelativeTimeElement_instances, _RelativeTimeElement_customTitle, _RelativeTimeElement_updating, _RelativeTimeElement_lang_get, _RelativeTimeElement_renderRoot, _RelativeTimeElement_getFormattedTitle, _RelativeTimeElement_getFormattedDate;
import { unitNames, microTimeAgo, microTimeUntil, timeUntil, timeAgo, elapsedTime } from './duration-format.js';
import { isDuration, withinDuration } from './duration.js';
const root = (typeof globalThis !== 'undefined' ? globalThis : window);
const HTMLElement = root.HTMLElement || null;
export class RelativeTimeUpdatedEvent extends Event {
    constructor(oldText, newText, oldTitle, newTitle) {
        super('relative-time-updated', { bubbles: true, composed: true });
        this.oldText = oldText;
        this.newText = newText;
        this.oldTitle = oldTitle;
        this.newTitle = newTitle;
    }
}
function getUnitFactor(el) {
    if (!el.date)
        return Infinity;
    if (el.format === 'elapsed') {
        const precision = el.precision;
        if (precision === 'second') {
            return 1000;
        }
        else if (precision === 'minute') {
            return 60 * 1000;
        }
    }
    const ms = Math.abs(Date.now() - el.date.getTime());
    if (ms < 60 * 1000)
        return 1000;
    if (ms < 60 * 60 * 1000)
        return 60 * 1000;
    return 60 * 60 * 1000;
}
const dateObserver = new (class {
    constructor() {
        this.elements = new Set();
        this.time = Infinity;
        this.timer = -1;
    }
    observe(element) {
        if (this.elements.has(element))
            return;
        this.elements.add(element);
        const date = element.date;
        if (date && date.getTime()) {
            const ms = getUnitFactor(element);
            const time = Date.now() + ms;
            if (time < this.time) {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => this.update(), ms);
                this.time = time;
            }
        }
    }
    unobserve(element) {
        if (!this.elements.has(element))
            return;
        this.elements.delete(element);
    }
    update() {
        clearTimeout(this.timer);
        if (!this.elements.size)
            return;
        let nearestDistance = Infinity;
        for (const timeEl of this.elements) {
            nearestDistance = Math.min(nearestDistance, getUnitFactor(timeEl));
            timeEl.update();
        }
        this.time = Math.min(60 * 60 * 1000, nearestDistance);
        this.timer = setTimeout(() => this.update(), this.time);
        this.time += Date.now();
    }
})();
export default class RelativeTimeElement extends HTMLElement {
    constructor() {
        super(...arguments);
        _RelativeTimeElement_instances.add(this);
        _RelativeTimeElement_customTitle.set(this, false);
        _RelativeTimeElement_updating.set(this, false);
        _RelativeTimeElement_renderRoot.set(this, this.shadowRoot ? this.shadowRoot : this.attachShadow ? this.attachShadow({ mode: 'open' }) : this);
    }
    static get observedAttributes() {
        return [
            'second',
            'minute',
            'hour',
            'weekday',
            'day',
            'month',
            'year',
            'time-zone-name',
            'prefix',
            'threshold',
            'tense',
            'precision',
            'format',
            'datetime',
            'lang',
            'title'
        ];
    }
    get second() {
        const second = this.getAttribute('second');
        if (second === 'numeric' || second === '2-digit')
            return second;
    }
    set second(value) {
        this.setAttribute('second', value || '');
    }
    get minute() {
        const minute = this.getAttribute('minute');
        if (minute === 'numeric' || minute === '2-digit')
            return minute;
    }
    set minute(value) {
        this.setAttribute('minute', value || '');
    }
    get hour() {
        const hour = this.getAttribute('hour');
        if (hour === 'numeric' || hour === '2-digit')
            return hour;
    }
    set hour(value) {
        this.setAttribute('hour', value || '');
    }
    get weekday() {
        const weekday = this.getAttribute('weekday');
        if (weekday === 'long' || weekday === 'short' || weekday === 'narrow')
            return weekday;
    }
    set weekday(value) {
        this.setAttribute('weekday', value || '');
    }
    get day() {
        var _a;
        const day = (_a = this.getAttribute('day')) !== null && _a !== void 0 ? _a : 'numeric';
        if (day === 'numeric' || day === '2-digit')
            return day;
    }
    set day(value) {
        this.setAttribute('day', value || '');
    }
    get month() {
        var _a;
        const month = (_a = this.getAttribute('month')) !== null && _a !== void 0 ? _a : 'short';
        if (month === 'numeric' || month === '2-digit' || month === 'short' || month === 'long' || month === 'narrow') {
            return month;
        }
    }
    set month(value) {
        this.setAttribute('month', value || '');
    }
    get year() {
        var _a;
        const year = this.getAttribute('year');
        if (year === 'numeric' || year === '2-digit')
            return year;
        if (!this.hasAttribute('year') && new Date().getUTCFullYear() !== ((_a = this.date) === null || _a === void 0 ? void 0 : _a.getUTCFullYear())) {
            return 'numeric';
        }
    }
    set year(value) {
        this.setAttribute('day', value || '');
    }
    get timeZoneName() {
        const name = this.getAttribute('time-zone-name');
        if (name === 'long' ||
            name === 'short' ||
            name === 'shortOffset' ||
            name === 'longOffset' ||
            name === 'shortGeneric' ||
            name === 'longGeneric') {
            return name;
        }
    }
    set timeZoneName(value) {
        this.setAttribute('time-zone-name', value || '');
    }
    get prefix() {
        var _a;
        return (_a = this.getAttribute('prefix')) !== null && _a !== void 0 ? _a : 'on';
    }
    set prefix(value) {
        this.setAttribute('prefix', value);
    }
    get threshold() {
        const threshold = this.getAttribute('threshold');
        return threshold && isDuration(threshold) ? threshold : 'P30D';
    }
    set threshold(value) {
        this.setAttribute('threshold', value);
    }
    get tense() {
        const tense = this.getAttribute('tense');
        if (tense === 'past')
            return 'past';
        if (tense === 'future')
            return 'future';
        return 'auto';
    }
    set tense(value) {
        this.setAttribute('tense', value);
    }
    get precision() {
        const precision = this.getAttribute('precision');
        if (unitNames.includes(precision))
            return precision;
        return 'second';
    }
    set precision(value) {
        this.setAttribute('precision', value);
    }
    get format() {
        const format = this.getAttribute('format');
        if (format === 'micro')
            return 'micro';
        if (format === 'elapsed')
            return 'elapsed';
        return 'auto';
    }
    set format(value) {
        this.setAttribute('format', value);
    }
    get datetime() {
        return this.getAttribute('datetime') || '';
    }
    set datetime(value) {
        this.setAttribute('datetime', value);
    }
    get date() {
        const parsed = Date.parse(this.datetime);
        return Number.isNaN(parsed) ? null : new Date(parsed);
    }
    set date(value) {
        this.datetime = (value === null || value === void 0 ? void 0 : value.toISOString()) || '';
    }
    connectedCallback() {
        this.update();
    }
    disconnectedCallback() {
        dateObserver.unobserve(this);
    }
    attributeChangedCallback(attrName, oldValue, newValue) {
        if (oldValue === newValue)
            return;
        if (attrName === 'title') {
            __classPrivateFieldSet(this, _RelativeTimeElement_customTitle, newValue !== null && __classPrivateFieldGet(this, _RelativeTimeElement_instances, "m", _RelativeTimeElement_getFormattedTitle).call(this) !== newValue, "f");
        }
        if (!__classPrivateFieldGet(this, _RelativeTimeElement_updating, "f") && !(attrName === 'title' && __classPrivateFieldGet(this, _RelativeTimeElement_customTitle, "f"))) {
            __classPrivateFieldSet(this, _RelativeTimeElement_updating, (async () => {
                await Promise.resolve();
                this.update();
            })(), "f");
        }
    }
    update() {
        const oldText = __classPrivateFieldGet(this, _RelativeTimeElement_renderRoot, "f").textContent || '';
        const oldTitle = this.getAttribute('title') || '';
        let newTitle = oldTitle;
        let newText = oldText;
        const now = new Date();
        if (!__classPrivateFieldGet(this, _RelativeTimeElement_customTitle, "f")) {
            newTitle = __classPrivateFieldGet(this, _RelativeTimeElement_instances, "m", _RelativeTimeElement_getFormattedTitle).call(this) || '';
            if (newTitle)
                this.setAttribute('title', newTitle);
        }
        newText = __classPrivateFieldGet(this, _RelativeTimeElement_instances, "m", _RelativeTimeElement_getFormattedDate).call(this, now) || '';
        if (newText) {
            __classPrivateFieldGet(this, _RelativeTimeElement_renderRoot, "f").textContent = newText;
        }
        else if (this.shadowRoot === __classPrivateFieldGet(this, _RelativeTimeElement_renderRoot, "f") && this.textContent) {
            __classPrivateFieldGet(this, _RelativeTimeElement_renderRoot, "f").textContent = this.textContent;
        }
        if (newText !== oldText || newTitle !== oldTitle) {
            this.dispatchEvent(new RelativeTimeUpdatedEvent(oldText, newText, oldTitle, newTitle));
        }
        const date = this.date;
        const format = this.format;
        const isRelative = (format === 'auto' || format === 'micro') && date && withinDuration(now, date, this.threshold);
        if (format === 'elapsed' || isRelative) {
            dateObserver.observe(this);
        }
        else {
            dateObserver.unobserve(this);
        }
        __classPrivateFieldSet(this, _RelativeTimeElement_updating, false, "f");
    }
}
_RelativeTimeElement_customTitle = new WeakMap(), _RelativeTimeElement_updating = new WeakMap(), _RelativeTimeElement_renderRoot = new WeakMap(), _RelativeTimeElement_instances = new WeakSet(), _RelativeTimeElement_lang_get = function _RelativeTimeElement_lang_get() {
    var _a, _b;
    return (_b = (_a = this.closest('[lang]')) === null || _a === void 0 ? void 0 : _a.getAttribute('lang')) !== null && _b !== void 0 ? _b : 'default';
}, _RelativeTimeElement_getFormattedTitle = function _RelativeTimeElement_getFormattedTitle() {
    const date = this.date;
    if (!date)
        return;
    if (typeof Intl === 'undefined' || !Intl.DateTimeFormat)
        return;
    return new Intl.DateTimeFormat(__classPrivateFieldGet(this, _RelativeTimeElement_instances, "a", _RelativeTimeElement_lang_get), {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short'
    }).format(date);
}, _RelativeTimeElement_getFormattedDate = function _RelativeTimeElement_getFormattedDate(now = new Date()) {
    const date = this.date;
    if (!date)
        return;
    const format = this.format;
    if (format === 'elapsed') {
        const precisionIndex = unitNames.indexOf(this.precision) || 0;
        const units = elapsedTime(date).filter(unit => unitNames.indexOf(unit[1]) >= precisionIndex);
        return units.map(([int, unit]) => `${int}${unit[0]}`).join(' ') || `0${this.precision[0]}`;
    }
    const tense = this.tense;
    const micro = format === 'micro';
    const inFuture = now.getTime() < date.getTime();
    const within = withinDuration(now, date, this.threshold);
    const locale = __classPrivateFieldGet(this, _RelativeTimeElement_instances, "a", _RelativeTimeElement_lang_get);
    if (typeof Intl !== 'undefined' && Intl.RelativeTimeFormat) {
        const relativeFormat = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' });
        if (tense === 'past' || (tense === 'auto' && !inFuture && within)) {
            const [int, unit] = micro ? microTimeAgo(date) : timeAgo(date);
            if (micro)
                return `${int}${unit[0]}`;
            return relativeFormat.format(int, unit);
        }
        if (tense === 'future' || (tense === 'auto' && inFuture && within)) {
            const [int, unit] = micro ? microTimeUntil(date) : timeUntil(date);
            if (micro)
                return `${int}${unit[0]}`;
            return relativeFormat.format(int, unit);
        }
    }
    if (typeof Intl === 'undefined' || !Intl.DateTimeFormat)
        return;
    const formatter = new Intl.DateTimeFormat(locale, {
        second: this.second,
        minute: this.minute,
        hour: this.hour,
        weekday: this.weekday,
        day: this.day,
        month: this.month,
        year: this.year,
        timeZoneName: this.timeZoneName
    });
    return `${this.prefix} ${formatter.format(date)}`.trim();
};
