const durationRe = /^[-+]?P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;
export const isDuration = (str) => durationRe.test(str);
export class Duration {
    constructor(years = 0, months = 0, weeks = 0, days = 0, hours = 0, minutes = 0, seconds = 0) {
        this.years = years;
        this.months = months;
        this.weeks = weeks;
        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
    }
    abs() {
        return new Duration(Math.abs(this.years), Math.abs(this.months), Math.abs(this.weeks), Math.abs(this.days), Math.abs(this.hours), Math.abs(this.minutes), Math.abs(this.seconds));
    }
    static from(durationLike) {
        var _a;
        if (typeof durationLike === 'string') {
            const str = String(durationLike).trim();
            const factor = str.startsWith('-') ? -1 : 1;
            const parsed = (_a = str
                .match(durationRe)) === null || _a === void 0 ? void 0 : _a.slice(1).map(x => (Number(x) || 0) * factor);
            if (!parsed)
                return new Duration();
            return new Duration(...parsed);
        }
        else if (typeof durationLike === 'object') {
            const { years, months, weeks, days, hours, minutes, seconds } = durationLike;
            return new Duration(years, months, weeks, days, hours, minutes, seconds);
        }
        throw new RangeError('invalid duration');
    }
}
export function applyDuration(date, duration) {
    const r = new Date(date);
    r.setFullYear(r.getFullYear() + duration.years);
    r.setMonth(r.getMonth() + duration.months);
    r.setDate(r.getDate() + duration.weeks * 7 + duration.days);
    r.setHours(r.getHours() + duration.hours);
    r.setMinutes(r.getMinutes() + duration.minutes);
    r.setSeconds(r.getSeconds() + duration.seconds);
    return r;
}
export function withinDuration(a, b, str) {
    const duration = Duration.from(str).abs();
    const threshold = applyDuration(a, duration);
    if (!threshold)
        return true;
    return Math.abs(Number(threshold) - Number(a)) > Math.abs(Number(a) - Number(b));
}
